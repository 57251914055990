//TODO ENDPOINT_URLS and ENDPOINT TAGS can be the same

export enum ENDPOINT_TAGS {
    TARIFF = 'tariff-structure',
    APPLICATION_GROUPS = 'application-group',
    AGGREGATIONS = 'aggregations',
    TRADE_GROUP = 'trade-group',
    GENERATOR = 'generator',
    TRADER = 'trader',
    RECON = 'recon',
    OFFTAKER = 'offtaker',
    METER = 'meter',
    GRID = 'grid',
    REPORT = 'report',
}

export enum TagId {
    PARTIAL_LIST = 'PARTIAL-LIST',
}

export enum API_METHODS {
    POST = 'POST',
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    PUT = 'PUT'
}
